.checkpoint {
  width: 80%;
  text-align: left;
}

.choice-paper {
  width: 100%;
  padding: 5px 5px 5px 20px;
  margin-top: 15px;
}

.question-title {
  margin-bottom: 0;
}

.choice-paper:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.choice-paper-selected-correct {
  background-color: green !important;
  color: white !important;
}

.choice-paper-selected-incorrect {
  background-color: #ab003c !important;
  color: white !important;
}

.choice-msg-wrapper-hidden {
  display: none !important;
}

.choice-msg-wrapper-show {
  animation: fadeIn 0.5s;
}

.choice-msg {
  width: 100%;
}

.text-answer {
  min-width: 99% !important;
  max-width: 99% !important;
  min-height: 150px !important;
  max-height: 350px !important;
}

.text-answer-submit {
  text-transform: none !important;
}

.tags-container {
  margin-top: 20px;
  min-width: 400px;
}

.tag {
  background-color: rgb(233, 224, 235);
  padding: 5px;
  border-radius: 3px !important;
}

.hint-popover {
  padding-left: 10px !important;
  padding-right: 10px !important;
  line-height: 25px !important;
}

.wide-image {
  width: 100%;
  margin-top: 20px;
}

.question-link {
  margin-top: 20px;
}

.file-downlowd-icon {
  min-width: 22px !important;
  width: fit-content !important;
}

.auto-width-image {
  width: auto;
}