.course-video {
}

.course-vid {
  width: 80%;
  /* border: 1px solid rgb(231, 226, 226); */
}

.speed-dial {
  /* width: 30px !important; */
  width: 20% !important;
  height: 20px !important;
  background-color: aliceblue;
}

.enroll-button-under-video {
  display: block;
  margin-top: 20px !important;
  width: 150px;
}