.youtube-embed {
  width: 100%;
  text-align: center;
}

.video-title {
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  .video-title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
