.course-card {
  text-align: center;
  display: flex;
  width: 300px;
  height: 300px;
  margin: auto;
  border-radius: 50px;
  box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);
  justify-content: space-around;
  align-content: center;
  flex-direction: column;
  background-size: cover;
  /* background: linear-gradient(90deg, #0f0c29 0%, #302b63 35%, #24243e 100%); */
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

/* .card:hover {
  border-left: rgb(241, 12, 12) 5px solid;
} */

.course-card:hover {
  box-shadow: 3px 4px 0 0 rgba(199, 186, 186, 0.2);
  color: white;
}

.card-text {
  display: block;
  font-size: 30px;
  color: white;
  font-weight: 600;
  text-shadow: 0 0 20px rgb(0 0 0 / 60%);
  padding-top: 25px;
  white-space: pre-wrap;
  height: 70px;
}

.card-guide {
  display: block;
  color: antiquewhite;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre-wrap;
  height: 100px;
}

.leetcode {
  width: 20%;
}

.chip {
  width: 120px;
  /* background-color: aliceblue !important; */
  display: flex;
  align-self: center;
}

.click-chip {
  cursor: pointer !important;
  color: white !important;
  width: 150px;
  background-color: rgb(20, 137, 35) !important;
}

@media screen and (max-width: 600px) {
  .course-card {
    width: 250px;
    height: 250px;
  }

  .card-text {
    font-size: 26px;
  }
}

.card-link {
  text-decoration: none;
}

.no-click-card {
  cursor: default;
}

.disabled-chip {
  background-color: rgb(153, 155, 154) !important;
  width: 150px;
}