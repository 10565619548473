
.career-coaching-faq {
    margin-top: 100px;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 100px;
  }

.career-coaching-faq-title {
    color: #6246ea;
    font-size: 34px;
    text-align: center;
    font-weight: bold;
    margin: 30px;
  }

  .faq-accordion {
    background-color: white;
    border: none;
    box-shadow: none;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .faq-accordion-question {
    font-size: 20px !important;
    color:#6246ea;
    font-weight: bold !important;
    font-family: Neuehaasunicaw1g,sans-serif;
    line-height: 20px !important;
  }

  .faq-accordion-question::first-line {
    line-height: 70px !important;
  }

  .faq-accordion-answer {
    color:rgb(147, 145, 145);
    font-family: Neuehaasunicaw1g,sans-serif;
  }


  @media screen and (max-width: 800px) {
    .career-coaching-faq {
        margin: 20px;
      }

      .faq-accordion-question {
        font-size: 16px !important;
        line-height: 8px !important;
      }
  }