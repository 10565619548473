.checkout-container {
  background-color: #f4f8f8;
  /* background-color: black; */
}

.product-img-container {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  text-align: center;
}

.product-img {
  width: 60%;
  /* border: 1px black solid; */
  /* background: linear-gradient(60deg, #ebe8e7 0%, #e9e4e4, #eb0404 100%); */
  /* background: radial-gradient(
    circle at 4%,
    rgb(238, 234, 234) 50%,
    rgb(221, 127, 230) 65%,
    rgb(135, 95, 211) 75%
  ); */
  border-radius: 5px;
}

.but-peek-buttons-container {
  width: 75%;
  text-align: center !important;
}

.buy-button {
  width: 250px !important;
  height: 50px !important;
}

.product-paper {
  /* height: 400px; */
  /* background-color: aliceblue !important; */
  text-align: center;
}

.product-desc {
  padding: 20px;
  margin-left: 0 !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  background-color: white !important;
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border: 1px solid rgb(233, 225, 233);
}

.what-you-learn {
  padding-left: 50px;
  padding-right: 50px;
}

.what-you-learn-paper {
  /* padding: 20px; */
  width: 80%;
}

.what-you-learn-title {
  margin: 30px !important;
  font-size: 32px !important;
  text-align: center;
}

.what-you-learn-desc {
  padding-left: 15px !important;
  border-left: 1px solid gray;
}

.syl-icon {
  color: white;
  padding: 10px;
  border-radius: 50%;
  background: linear-gradient(
    95deg,
    rgb(242, 113, 33) 0%,
    rgb(233, 64, 87) 50%,
    rgb(138, 35, 135) 100%
  );
  margin-top: 0 !important;
}

.syl-icon-wrapper {
  z-index: 2;
  margin-top: 0 !important;
}

.list-what-you-learn {
  /* border-left: 1px solid gray !important;
  margin-left: 2em; */
}

.divi {
  background-color: rgb(240, 227, 227) !important;
  position: relative;
  left: 24px;
  top: 15px;
  z-index: 1;
  border-right-width: 5px !important;
}

.arrow {
  border: 1px solid black;
  border-radius: 50%;
  /* padding: 5px; */
}

.instructor-title {
  margin-top: 30px !important;
}

.instructor-wrapper {
  padding: 20px;
  background-color: rgb(148, 31, 103);
  border-radius: 120px;
  margin-bottom: 10px;
  color: white;
  font-size: large;
  line-height: 27px;
}

.instructor {
  border-radius: 50%;
  width: 200px;
  border: 3px solid rgb(236, 234, 230);
  z-index: 3;
}

.instructor-blurb {
  padding-top: 25px;
}

.bottom-enroll-button {
  margin-bottom: 100px !important;
  padding-left: 0 !important;
}

.peek-inside-button-checkout {
  background-color: #673ab7 !important;
  color: white !important;
  width: 250px !important;
  height: 50px !important;
  margin-left: 20px !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
}

.instructors-container {
  width: 75% !important;
}

@media screen and (max-width: 900px) {
  .peek-inside-button-checkout {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 600px) {
  .peek-inside-button-checkout {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .what-you-learn-title {
    font-size: 28px !important;
  }

  .what-you-learn {
    padding-left: 25px !important;
    padding-right: 10px !important;
  }
  .what-you-learn-paper {
    width: 100%;
  }

  .product-img-container {
    /* for some reason buttons are not centerd so image should be skewd left to look centered overall */
    margin-left: -13px !important;
  }

  .product-img {
    width: 80%;
  }

  .product-desc {
    margin-left: 15px !important;
  }
}

.peek-inside-button-checkout:hover {
  background-color: #4527a0 !important;
  color: white !important;
}

.flag-icon {
  color: white;
  padding: 10px;
  border-radius: 50%;
  background: linear-gradient(
    95deg,
    rgb(242, 113, 33) 0%,
    rgb(233, 64, 87) 50%,
    rgb(138, 35, 135) 100%
  );
  margin-top: 0 !important;
}

.flag-icon-wrapper {
  margin-left: 4px;
  z-index: 2;
  margin-top: 0 !important;
}

.continue-button {
  width: 250px !important;
  height: 50px !important;
}

.strikethrough {
  text-decoration: line-through;
  color: rgb(231, 178, 142) !important;
}

.reduced-price {
  text-align: center;
  padding-top: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 14px;
}


.price-249 {
  /* text-decoration: line-through;
  color: rgb(254, 118, 118); */
}

.shorter-purchase {
  min-height: 250px !important;
  height: auto !important;
  margin-bottom: 15px;
 
}

.shorter-purchase-txt {
  height: 150px !important;
  min-width: 291px !important;
  
}

.card-shorter {
  height: auto !important;
  min-width: 291px !important;
}

.wider-card {
  /* width: 400px !important; */
}

.extra {
  background-color: #673ab7 !important;
}

.course-only {
  background-color: #2e7d32 !important;
  color: white !important
}

.course-only-body {
  /* background-color: #E4EFE7 !important; */
}