.landing-slant {
  height: 770px;
  background: linear-gradient(145deg, lightblue, rgb(27, 27, 114), black);
  width: 100%;
  position: relative;
}

.landing-slant:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 100vw 300px 0px;
  border-color: transparent transparent white transparent;
}

.landing-title {
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white; */
  color: balck;
  font-size: 70px;
  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
  /* font-family: Neuehaasunicaw1g,sans-serif; */
  font-weight: 900;
  left: 15%;
  position: absolute;
  top: 13% !important;
  width: 670px;
  color: white;
  /* -webkit-text-stroke: 2px black; */
  /* animation: color-change 20s infinite; */
}

@keyframes color-change {
  0% { color: rgb(255, 255, 255); }
  30% { color: rgb(11, 1, 79); }
  70% { color: rgb(255, 255, 255); }
  100% { color: rgb(255, 255, 255); }
}

.landing-subtitle {
  font-size: 34px;
  color: white;
  position: absolute;
  left: 16%;
  top: 23%;
}

.fe-track-card {
  position: absolute !important;
  right: 20% !important;
  top: 10%;
  z-index: 15;
}


@media screen and (max-width: 1600px) {
  .landing-title {
    font-size: 60px;
    width: 600px;
  }
}


@media screen and (max-width: 1450px) {
  .landing-title {
    font-size: 50px;
    width: 550px;
    left: 13% !important;
  }

  .landing-subtitle {
    left: 9% !important;
  }

  .fe-track-card {
    right: 13% !important;
    z-index: 15;
  }
}

@media screen and (max-width: 1200px) {
  .landing-title {
    position: unset;
    display: block;
    width: 100%;
    text-align: center;
    left: 17% !important;
  }

  .landing-subtitle {
    position: unset;
    display: block;
    width: 100%;
    text-align: center;
    left: 14% !important;
  }

  .fe-track-card {
    display: flex;
    min-width: 350px;
    flex-shrink: 0 !important;
    position: unset !important;
    right: unset !important;
    top: 18%;
    margin: auto !important;
    justify-content: center !important;
    z-index: 15;
  }
}

@media screen and (max-width: 600px) {
  .landing-slant {
    text-align: center;
  }

  .landing-title {
    font-size: 40px;
    padding: 15px;
    margin: auto;
    width:fit-content;
  }

  .landing-subtitle {
    font-size: 20px;
    padding-top: 10px;
  }

  .fe-track-card {
    top: 15%;
    padding-top: 10px;
    z-index: 15;
  }

  .landing-slant:after {
    border-width: 0px 100vw 80px 0px;
  }
}
