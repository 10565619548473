.track-card {
  cursor: pointer;
  z-index: 15;
  display: flex;
  flex-direction: column;
  height: 475px;
  overflow: hidden;
  position: relative;

  background-color: #e5e5f7;
  background-image: linear-gradient(#0d020e 2px, transparent 2px),
    linear-gradient(90deg, #0d020e 2px, transparent 2px),
    linear-gradient(#0d020e 1px, transparent 1px),
    linear-gradient(90deg, #0d020e 1px, #e5e5f7 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

.card-main {
  height: 100%;
  z-index: 1;
}

.card-main-text {
  height: 100%;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 48px;
  /* color: rgb(0, 42, 255); */
  /* background: linear-gradient(to right, white 50%, white 70%, #e24e13 90%);
  color: transparent;
  background-clip: text; */
  color: white;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

/* .card-ribbon {
  background: linear-gradient(
    180deg,
    #afaeae,
    #e2e2e2 15%,
    #e2e2e2 90%,
    #afaeae 95%
  ) !important;
  color: black;
} */

/* .card-ribbon {
  background-color: rgb(21 21 91);
  color: white;
} */

.card-ribbon {
  background-color: white;
}

.ribbon-text {
  text-align: left;
}

.card-actions {
  z-index: 10;
  background-color: rgb(255, 255, 255);
}

.action-alt {
  line-height: 52px;
  padding-left: 15px;
}

.preorder-button {
  width: 50%;
}

.track-card:hover {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
}

.peek-inside-button {
  width: 50%;
  background-color: #673ab7 !important;
  color: white !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
}

.peek-inside-button:hover {
  background-color: #4527a0 !important;
  color: white !important;
}

.animated-card {
  /* background: linear-gradient(-45deg, #ed303a, #ff7b30, #fbd403, #ff6539); */
  /* background: linear-gradient(-45deg, #1ea896, #1ea896, #1ea896, #1ea896); */
  background: linear-gradient(-45deg, #b99799, #fb3640, #f3d633, #f50d19);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card-circle {
  position: absolute;
  top: -100px;
  left: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: white;
  z-index: 0;
}

.card-circle-2 {
  position: absolute;
  top: -150px;
  right: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #673ab7;
  z-index: -1;
}

.card-circle-pattern {
  position: absolute;
  top: -100px;
  left: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  z-index: 1;
  background-color: #e5e5f7;
  opacity: 0.1;
  background-image: linear-gradient(
      30deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      150deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      30deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      150deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      60deg,
      #fb364077 25%,
      transparent 25.5%,
      transparent 75%,
      #fb364077 75%,
      #fb364077
    ),
    linear-gradient(
      60deg,
      #fb364077 25%,
      transparent 25.5%,
      transparent 75%,
      #fb364077 75%,
      #fb364077
    );
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

.card-circle-pattern-2 {
  position: absolute;
  top: -150px;
  right: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  z-index: 1;
  background-color: #e5e5f7;
  opacity: 0.1;
  background-image: linear-gradient(
      30deg,
      #eb36fb 12%,
      transparent 12.5%,
      transparent 87%,
      #eb36fb 87.5%,
      #eb36fb
    ),
    linear-gradient(
      150deg,
      #eb36fb 12%,
      transparent 12.5%,
      transparent 87%,
      #eb36fb 87.5%,
      #eb36fb
    ),
    linear-gradient(
      30deg,
      #eb36fb 12%,
      transparent 12.5%,
      transparent 87%,
      #eb36fb 87.5%,
      #fb3640
    ),
    linear-gradient(
      150deg,
      #eb36fb 12%,
      transparent 12.5%,
      transparent 87%,
      #eb36fb 87.5%,
      #eb36fb
    ),
    linear-gradient(
      60deg,
      #fb364077 25%,
      transparent 25.5%,
      transparent 75%,
      #fb364077 75%,
      #fb364077
    ),
    linear-gradient(
      60deg,
      #fb364077 25%,
      transparent 25.5%,
      transparent 75%,
      #fb364077 75%,
      #fb364077
    );
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

.card-square {
  position: absolute;
  top: 230px;
  left: 200px;
  width: 200px;
  height: 200px;
  background-color: white;
  z-index: 0;
  transform: rotate(45deg);
}

.card-square-pattern {
  position: absolute;
  top: 230px;
  left: 200px;
  width: 200px;
  height: 200px;
  z-index: 1;
  transform: rotate(45deg);

  background-color: #e5e5f7;
  opacity: 0.1;
  background-image: linear-gradient(
      30deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      150deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      30deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      150deg,
      #fb3640 12%,
      transparent 12.5%,
      transparent 87%,
      #fb3640 87.5%,
      #fb3640
    ),
    linear-gradient(
      60deg,
      #fb364077 25%,
      transparent 25.5%,
      transparent 75%,
      #fb364077 75%,
      #fb364077
    ),
    linear-gradient(
      60deg,
      #fb364077 25%,
      transparent 25.5%,
      transparent 75%,
      #fb364077 75%,
      #fb364077
    );
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}
