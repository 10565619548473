.signup-fail {
  margin-top: 15px !important;
  text-align: center;
  width: 320px;
  padding: 8px;
  margin: auto;
  background-color: rgba(247, 237, 91, 0.6);
  color: rgb(241, 28, 28);
  font-weight: 500;
  border-radius: 5px;
  /* border: rgb(230, 11, 11) solid 2px; */
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
