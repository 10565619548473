.oso-philosophy-wrapper {
  width: 80%;
  margin: auto;
  margin-top: 80px;
}

.oso-philosophy {
  padding: 10px;
  min-height: 200px;
  /* background-color: rgb(58, 153, 236); */
  background: linear-gradient(145deg, lightblue, rgb(27, 27, 114), black);
  border-bottom-left-radius: 150px;
  border-top-right-radius: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.oso-philosophy-text {
  color: white !important;
  padding: 20px;
}
