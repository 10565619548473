.career-coaching-background {
  position: absolute;
  top: 600px;
  width:100%;
  height: 1100px;
  background: lightgray;
  opacity: 0.3;
  z-index: -1;
}

.career-coaching-page-title {
  text-align: center;
  font-size: 84px;
  font-weight: bold;
  padding: 50px;
}
.career-coaching-container {
  text-align: center;
}

.career-coaching-card {
  height: 600px;
  border-radius: 7px;
  min-width: 270px;
  max-width: 500px !important;
}

.career-coaching-card-title {
  height: 150px;
  border-radius: 6px 6px 0 0;
  padding: 20px;
}

.career-coaching-card-title-single {
  color: black;
  background-color: #E4EFE7;
  height: 150px;
  border-radius: 6px 6px 0 0;
  padding: 20px;
}

.career-coaching-card-title-break-into {
  color: white;
  background-color: #6246ea;
  height: 150px;
  border-radius: 6px 6px 0 0;
  padding: 20px;
}

.career-coaching-card-title-top-tech {
  color: white;
  background-color: #E94560;
  height: 150px;
  border-radius: 6px 6px 0 0;
  padding: 20px;
}

.career-coaching-card-title-name {
  font-size: 22px;
  font-weight: bold;
}

.career-coaching-card-title-subinfo {
  margin-top: 15px;
  font-size: 14px;
  height: 50px;
}

.career-coaching-card-title-cost {
  margin-top: 35px;
  font-size: 24px;
  font-weight: bold;
}

.career-coaching-card-desc-single {
  background-color: white;
  border: 1px solid lightgray;
  border-top: 0px;
  height: 375px;
  border-radius: 0 0 6px 6px;
  padding-top: 20px;
}

.career-coaching-card-desc-break-into {
  background-color: #d1d1e9;
  height: 375px;
  border-radius: 0 0 6px 6px;
  padding-top: 20px;
}

.career-coaching-card-desc-top-tech {
  background-color: #0f0e17;
  color: white;
  height: 375px;
  border-radius: 0 0 6px 6px;
  padding-top: 20px;
}

.career-coaching-card-desc-text {
  height: 80%
}

.career-coaching-card-desc-text-top {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px
}

.career-coach-purchase-button {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  width: 80%;
}

.career-coach-purchase-button-single {
  background-color: white !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  width: 80%;
}

.career-coach-purchase-button-break-into {
  background-color: #6246ea !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  width: 80%;
  color: white !important;
  border: none !important;
}

.career-coach-purchase-button-top-tech {
  background-color: #E94560 !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  width: 80%;
  color: white !important;
  border: none !important;
}

.career-coaching-bullets-icon {
  min-width: 30px !important;
  width: 30px !important;
}

.career-coaching-bullets {
  line-height: 10px !important;
}

.career-coaching-bullet-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.career-coaching-vid {
  margin: 100px !important;
  text-align: center;
  margin: auto;
}

.career-coaching-vid-element {
  min-width: 700px;
  max-width: 1200px;
}



@media screen and (max-width: 800px) {
  .career-coaching-page-title {
    font-size: 54px;
    padding: 25px;
  }

  .career-coaching-card {
    margin-left:20px !important;
    margin-right:20px !important;
  }

  .career-coaching-vid {
    margin: 0 !important;
    text-align: center;
  }

  .career-coaching-vid-element {
    min-width: 350px !important;
    width: 80%;
  }

}