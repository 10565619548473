.about-us-wrapper {
  margin-top: 120px;
  margin-bottom: 50px;
  text-align: left;
}

.about-us-container-text {
  padding: 30px;
}

.about-us-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-us-subtext {
  margin-bottom: 10px;
}

.about-us-image {
  margin-top: 30px;
  width: 300px;
  border-radius: 50%;
}

.company-logo {
  width: 120px;
  margin-right: 20px;
  margin-top: 10px;
  border-radius: 20px;
}

@media screen and (max-width: 800px) {
  .about-us-wrapper {
    margin-top: 60px;
    margin-bottom: 50px;
    /* text-align: center; */
  }

  .about-us-container {
    justify-content: center;
  }

  .about-us-image {
    margin: auto;
  }

  .about-us-title {
    font-size: 32px;
  }

  .company-logo {
    width: 80px;
    margin-right: 20px;
    margin-top: 10px;
    border-radius: 20px;
  }
}
