.career-track {
  display: flex;
  /* overflow: hidden; */
  /* overflow-x: hidden;
  overflow-y: auto; */
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  /* position: relative; */
  width: 100%;
  backface-visibility: hidden;
  /* will-change: overflow; */
}

.side-panel {
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  /* overflow-y: auto; */
  float: left;
  width: fit-content;
  overflow-x: hidden;
  background-color: #eeeff5;
  /* background-color: rgb(34, 26, 26); */
  min-width: 320px;
}

.main-course-screen {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  padding: 15px;
  padding-top: 30px;
  margin-left: 0px !important;
}

.main-course-screen::-webkit-scrollbar {
  display: none;
}

.list {
  width: 320px;
}

.chapter-heading {
  background-color: #320933 !important;
  color: white !important;
  border: 1px outset rgba(65, 28, 68, 0.5) !important;
}

.no-vertical-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.topic {
  background-color: #c5cae9 !important;
  border: 1px outset rgba(231, 225, 230, 0.5) !important;
  /* color: white !important; */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background-color: #c6c6cc;
  border-radius: 5px;
}

.general-item {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  /* height: 45px; */
  font-size: 14px !important;
}

.list-item-text {
  margin: 0px !important;
}

.selected {
  background-color: rgb(255, 255, 255) !important;
  border-left: 5px solid salmon !important;
  font-weight: 700 !important;
}

.unselected {
  margin-left: 5px !important;
}

.completed-button-wrapper {
  width: 80% !important;
  margin: 10px !important;
}

.completed-button {
  /* background-color: rgb(210, 228, 209) !important;
  color: black !important; */
}

.sub-info {
  padding-left: 2px;
  font-size: 12px;
}

.bottom-panel {
  display: none;
}

.track-name {
  border: 1px outset rgba(46, 38, 45, 0.5);
  background-color: rgb(34, 26, 26) !important;
  line-height: 25px !important;
  padding: 10px !important;
  color: white !important;
}

.progress-line {
  display: inline-block;
  border: 1px solid rgb(159, 139, 161);
  border-radius: 5px;
  width: 220px;
  height: 5px;
  margin-right: 12px;
}

.completed-icons {
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 800px) {
  .side-panel {
    display: none;
  }

  .bottom-panel {
    display: block;
    width: 100%;
  }

  .main-course-screen {
    padding: 0px;
  }

  .list {
    width: 100%;
  }
  .track-name {
    display: flex;
    flex-direction: column;
    padding-left: 17px !important;
    margin-top: 5px;
  }
}
