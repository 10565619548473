.policy-container {
  background-color: #f0f0f0;
  padding: 20px;
}

.main-policy-title {
  text-align: center;
  font-size: 42px;
  font-weight: 500;
  margin-top: 20px !important;
}

.policy-title {
  /* text-align: center; */
  margin-left: 20px !important;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px !important;
}

.policy-body {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
