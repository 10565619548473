.footer {
  height: 100px;
  background: linear-gradient(90deg, #0f0c29 0%, #000000 35%, #24243e 100%);
  font-size: small;
  padding: 10px;
  color: aliceblue;
  margin: auto;
}

.recaptcha-notice {
  font-size: x-small;
}

.recaptcha-notice a {
  color: white;
}

.footer-link {
  color: white !important;
}
