.landing {
  /* background: linear-gradient(90deg, #0f0c29 0%, #302b63 35%, #24243e 100%); */
}

.main-grid {
  /* background-image: url("../images/main-background.png"); */
  /* background-repeat: no-repeat; */
}

.all-career-tracks {
  /* background: linear-gradient(90deg, #0f0c29, #302b63 35%, #24243e); */
  background-color: rgb(226, 231, 236);
  /* background: linear-gradient(
    120deg,
    rgba(4, 36, 104, 0.877),
    rgba(6, 26, 92, 0.267) 80.71%
  ); */
}
