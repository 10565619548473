.offerings-wrapper {
  position: relative;
  text-align: center;
  padding-top: 20px;
  min-height: 500px;
  z-index: 10;
}

.offerings {
  /* background-color: red; */
  width: 300px !important;
  height: 300px !important;
  background-color: antiquewhite;
  margin: 10px;
  min-width: 300px !important;
  border-radius: 50px;
  color: white;
}

.offerings-title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 70px !important;
  padding: 10px;
}

.career-tracks-offering {
  background-image: url("../images/html-color.jpg");
  background-size: cover;
}

@media screen and (max-width: 800px) {
  .offerings-title {
    font-size: 32px;
    margin-bottom: 30px !important;
  }
}
