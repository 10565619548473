.menu-bar {
  /* background-color: white !important; */
}

.black-menu {
  background-color: rgb(34, 26, 26) !important;
}

.white-menu {
  background-color: white !important;
}

.white-text {
  color: white !important;
}

/* .login-button {
  width: 100px;
  background-color: rgb(30, 158, 13) !important;
}

.logout-button {
  width: 100px;
  color: black;
  background-color: rgb(230, 236, 229) !important;
} */

.user-account {
  color: rgb(115, 174, 194);
  padding-right: 7px;
}

.pipe {
  /* makes the pipe stay central in landing page for some reason */
  line-height: 4.06px;
  color: rgb(255, 123, 0);
}


@media screen and (max-width: 600px) {
  .user-account {
    display: none;
  }
}