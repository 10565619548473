.verify-email {
  background-color: rgb(55, 131, 39);
  border-radius: 5px;
  padding: 10px;
  color: white;
  text-align: center;
}

.login-to_enroll {
  background-color: rgb(55, 131, 39);
  border-radius: 5px;
  padding: 10px;
  color: white;
}
